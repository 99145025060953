@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
// app global css in SCSS form
// body.body--light {
//   .bg-page {
//     background-color: $white-page;
//   }
//   .bg-menu {
//     background-color: $grey;
//   }
//   .bg-navigation {
//     background-color: $grey;
//   }
//   .text-normal {
//     color: #333333;
//   }
//   .text-normal-invert {
//     color: white;
//   }
//   .text-grey {
//     color: #333333 !important;
//   }
// }

body.body--dark {
  .bg-page {
    background-color: $dark-page;
  }
  .bg-menu {
    background-color: $light-dark;
  }
  .bg-navigation {
    background-color: $dark;
  }
  .text-normal {
    color: white;
  }
  .text-normal-invert {
    color: black;
  }
}
